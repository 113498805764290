<template>
  <div>
  <skeleton-table v-if="initialize" :rows="10" :columns="fields.length" :buttons="1" :search="true" />

  <no-records-found
    v-if="showNotFound"
    text="No invalid contacts found in your contacts"
  ></no-records-found>

  <b-card v-else no-body class="action-buttons" :class="{'d-none': mainCardInvisiblity}">
    <b-row class="mb-1 mx-1">
      <b-col md="6" xl="8">
        <div class="inline-spacing">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="
              !selectedContacts.length ? 'outline-secondary' : 'outline-primary'
            "
            class="mr-50 mt-2"
            v-b-modal.delete-bulk-contact
            :disabled="!selectedContacts.length"
          >
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span class="align-middle">Delete</span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mr-50 mt-2"
            @click="downloadAllInvalidContacts"
            :disabled="downloadIsLoading"
            :class="{ disabled: downloadIsLoading }"
          >
            <b-spinner small v-if="downloadIsLoading" />
            &nbsp;
            <feather-icon icon="DownloadIcon" class="mr-50" />
            <span class="align-middle">Download</span>
          </b-button>
        </div>
      </b-col>

      <b-col md="6" xl="4" class="mt-2">
        <!-- basic -->
        <b-form-group>
          <b-input-group>

            <b-form-input v-model.trim="search" placeholder="Search for invalid contacts" />

            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon rounded-circle ml-1"
                @click="contactRefresh"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>

          </b-input-group>

        </b-form-group>

        
      </b-col>
    </b-row>

    <b-table
      show-empty
      empty-text="No records found from your search"
      empty-filtered-text="No records found from your search"
      hover
      striped
      :busy.sync="isBusy"
      :current-page="currentPage"
      :per-page="perPage"
      responsive
      :items="contacts"
      :fields="fields"
      ref="contactsTable"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #head(ID)>
        <b-form-checkbox
          v-model="allSelected"
          @change="selectAll"
          :value="true"
          :unchecked-value="false"
        >
        </b-form-checkbox>
      </template>

      <template #cell(ID)="data">
        <b-form-checkbox
          v-model="selectedContacts"
          :value="data.value"
        >
        </b-form-checkbox>
      </template>

      <template #cell(name)="row">
        <span> {{ row.item.first_name }} {{ row.item.last_name }}</span>
      </template>

      <template #cell(phone)="row">
        <span> {{ row.item.code }}{{ row.item.phone }}</span>
      </template>

      <template #cell(actions)="row">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-info"
          class="btn-icon rounded-circle ml-lg-1 mt-1 mt-lg-0"
          v-b-tooltip.hover title="Edit Invalid Contact"
          @click="editContact(row.item.ID)"
        >
          <feather-icon icon="EditIcon" />
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-danger"
          class="btn-icon rounded-circle ml-lg-1 mt-1 mt-lg-0"
          v-b-tooltip.hover title="Delete Invalid Contact"
          v-b-modal.delete-contact
          @click="deleteContact(row.item.ID)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </template>
      
      <!-- <template #cell(actions)="row">
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>

          <b-dropdown-item
            @click="editContact(row.item.ID)"
          >
            <feather-icon icon="EditIcon" class="mr-50" />
            <span>Edit Invalid Contact</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-b-modal.delete-contact
            @click="deleteContact(row.item.ID)"
          >
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span>Delete Invalid Contact</span>
          </b-dropdown-item>
        </b-dropdown>
      </template> -->
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="center"
      size="lg"
      v-if="!noRecordsFound"
    ></b-pagination>
    
    <sidebar-edit-contact :id="contactId" :groups="groups" :countries="countries" @contact-submited="contactRefresh"  @reset-id="ResetContactId"/>
    <delete-single-contact :id="contactId" @contact-submited="contactRefresh" :invalid="true"/>
    <delete-bulk-contact :ids="selectedContacts" @contact-submited="contactRefresh" :invalid="true"/>
  </b-card>
  </div>
</template>

<script>
import {
  BPagination,
  BTable,
  BButton,
  BSpinner,
  BCard,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BRow,
  VBToggle,
  VBModal,
  VBTooltip,
} from "bootstrap-vue";
import SidebarEditContact from "./components/SidebarEditContact.vue";
import DeleteSingleContact from "./components/DeleteSingleContact.vue";
import DeleteBulkContact from "./components/DeleteBulkContact.vue";
import Ripple from "vue-ripple-directive";
import NoRecordsFound from "@/views/pages/no-records-found/NoRecordsFound.vue";
import SkeletonTable from "@/views/pages/shimmers/SkeletonTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  components: {
    BTable,
    BPagination,
    BCard,
    BButton,
    BSpinner,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
    SidebarEditContact,
    DeleteSingleContact,
    DeleteBulkContact,
    NoRecordsFound,
    SkeletonTable,
  },
  data() {
    return {
      fields: [
        {
          key: "ID",
          label: "SELECTION",
        },
        {
          key: "name",
          label: "NAME",
        },
        {
          key: "phone",
          label: "PHONE NUMBER",
        },
        {
          key: "email",
          label: "EMAIL",
        },
        {
          key: "format_created_at",
          label: "DATE ADDED",
        },
        {
          key: "actions",
          label: "ACTIONS",
        },
      ],
      selectedContacts: [],
      allContactsId: [],
      allSelected: false,
      groups: [],
      countries: [],
      search: "",
      contactId:false,
      downloadIsLoading:false,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      initialize: true,
      showNotFound: false,
      noRecordsFound: false,
      mainCardInvisiblity:true,
    };
  },
  watch:{
    $route (to, from){
      if(to.name == "client-all-contacts" && from.name == "client-group-contacts") {
        this.contactRefresh();
      }
    }
  },
  methods: {
    contacts(ctx, callback) {
      let params
      if (this.search) {
        params = "?page=" + ctx.currentPage + "&search=" + this.search;
      } else {
        params = "?page=" + ctx.currentPage;
      }
      this.$http
        .get("/contacts/invalid" + params)
        .then((response) => {
          this.totalRows = response.data.total_pages * 10;
          // Pluck the array of items off our axios response
          const items = response.data.data;
          if (items.length == 0) {
            this.noRecordsFound = true;

            if (this.initialize) {
              this.showNotFound = true;
            }
          } else {
            this.noRecordsFound = false;
          }
          this.initialize = false;
          this.isBusy = false;
          this.allSelected = false;
          this.allContactsId = [];
          this.selectedContacts = [];
          for (let contact of items) {
            this.allContactsId.push(contact.ID);
          }
          this.mainCardInvisiblity = false;
          callback(items);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          if (this.initialize) {
            this.showNotFound = true;
          }
          this.noRecordsFound = true;
          this.initialize = false;
          this.mainCardInvisiblity = false;
          callback([]);
          this.isBusy = false;
        });
      return null;
    },
    downloadAllInvalidContacts() {
      this.downloadIsLoading = true;
      this.$http
        .get("/contacts/download?invalid=true", {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "all-invalid-contacts-download.xlsx");
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "All Contacts Downloaded",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.downloadIsLoading = false;
          }, 1000);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          this.downloadIsLoading = false;
        });
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedContacts = this.allContactsId;
      } else {
        this.selectedContacts = [];
      }
    },
    contactRefresh() {
      this.contactId = false;
      this.$refs.contactsTable.refresh();
    },
    editContact(id) {
      this.contactId = id;
      this.$root.$emit('bv::toggle::collapse', 'sidebar-edit-contact')
    },
    deleteContact(id) {
      this.contactId = id;
    },
    ResetContactId(){
      this.contactId = false;
    },
  },
  created() {
    this.$http
      .get("/countries")
      .then((response) => {
        for (const country of response.data.data) {
          let pushCountry = {
            value: country.ID,
            text: country.title + " (" + country.code + ")",
          };
          this.countries.push(pushCountry);
        }
      })
      .catch((error) => {
        for (let err of error.response.data.error) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: err,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        }
      });

    this.$http
      .get("/contact-group/search")
      .then((response) => {
        for (const group of response.data.data) {
          let pushGroup = {
            value: group.ID,
            text: group.name,
          };
          this.groups.push(pushGroup);
        }
      })
      .catch((error) => {
        for (let err of error.response.data.error) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: err,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        }
      });
  }
};
</script>
